import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import "./Page.css";
import AssetTable from "./table";
const axios = require("axios").default;

export default function ResultsPage() {
  const { asset_id } = useParams("asset_id"); // useParams<{ asset_id: string }>();
  const url = "https://wpp.ngoandsons.com.au/api/point/" + asset_id;

  const [asset, setAsset] = useState({});

  useEffect(() => {
    axios
      .get(url)
      .then(function (response) {
        // handle success
        setAsset(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{asset.Points}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{asset.Points}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <AssetTable asset={asset} />
      </IonContent>
    </IonPage>
  );
}
