import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Page.css";

const Create: React.FC = () => {
  const name = "Suggestions";

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Suggestions</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          <p>
            Please email jonathan.lam@transport.nsw.gov.au if you would like to
            contribute new data
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Create;
