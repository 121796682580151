import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { useParams } from "react-router";
import "./Page.css";
import React, { useEffect, useState } from "react";
const axios = require("axios").default;

const SearchPage = () => {
  const { name } = useParams("name");

  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    axios
      .get("https://wpp.ngoandsons.com.au/api/points?q=" + searchText)
      .then(function (response) {
        // handle success
        console.log(response.data);
        setSearchResults(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, [searchText]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Search</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value)}
        ></IonSearchbar>
        <IonList>
          {searchResults?.map((result, i) => (
            <IonItem routerLink={"/asset/" + result.id}>
              <IonLabel>
                <h2>{result.points}</h2>
                <h3>{result.location}</h3>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default SearchPage;
